import React, { Component } from 'react';
import { Typography, Row, Col, Button } from 'antd';
import MainLayout from '../layouts/mainLayout'
import { Link } from 'react-router-dom';
const { Title } = Typography;

class pet_resource extends Component {

    onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    render() {
        return (
            <MainLayout>
                <div className="banner">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-pet-resource.jpg"
                    />
                    <div className="banner-caption mt-5">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-light-green tagline">Vets</Title>
                                        <Title level={3} className="text-white mt-0 mb-5 sub-tagline">We have some interesting materials for you !</Title>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="full-width-section vets-page-content full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green text-center section-title mb-5">Go the extra mile for your pet parents</Title>
                            </Col>
                        </Row>
                        <Row gutter={[15, 40]} justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={15} className="text-center">
                                <div className="pet-resource-block text-center">
                                    <img
                                        className="img-fluid"
                                        src="/images/oslo-resorce1.jpg"
                                    />
                                    <Button size="large" type="primary" className="btn-red">Download</Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={15} className="text-center">
                                <div className="pet-resource-block">
                                    <img
                                        className="img-fluid"
                                        src="/images/oslo-resorce2.jpg"
                                    />
                                    <Button size="large" type="primary" className="btn-red">Download</Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={15} className="text-center">
                                <div className="pet-resource-block">
                                    <img
                                        className="img-fluid"
                                        src="/images/oslo-resorce3.jpg"
                                    />
                                    <Button size="large" type="primary" className="btn-red">Download</Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Title level={2} className="text-green text-center mb-5">Go the extra mile for your pet parents</Title>
                            </Col>
                        </Row>
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={9} md={7} lg={6} xl={4}>
                                <Button size="large" block type="primary" className="btn-red">
                                    <Link className="no-text-decoration" to="/schedule_demo">Schedule a demo</Link>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default pet_resource;