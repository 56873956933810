import React, { Component } from 'react';
import { Typography, Collapse, Row, Col, Button, Form, Input } from 'antd';
import MainLayout from '../layouts/mainLayout'
import { Link } from "react-router-dom";
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

class faqs_page extends Component {
    render() {
        return (
            <MainLayout>
                <div className="banner content-center">
                    <img
                        className="img-fluid w-100"
                        src="/images/banner-faqs.jpg"
                    />
                    <div className="banner-caption">
                        <div className="container">
                            <Row gutter={15}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <div className="text-center">
                                        <Title level={1} className="text-green tagline">FAQs</Title>
                                        <Title level={3} className="text-green mt-0 mb-5 sub-tagline">So you have a question?  <br /> We are here to answer!</Title>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-get-started position-relative">
                                <Row gutter={15} align="middle" justify="center">
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form
                                            layout={"inline"}
                                            name="search"
                                            className="form-pet-search no-shadow"
                                            size={"large"}
                                        >
                                            <Form.Item
                                                name="search_pet"
                                                rules={[{ required: true, message: 'Please type your question keywords.' }]}
                                            >
                                                <Input placeholder="Type your question keywords" />
                                            </Form.Item>
                                            <Form.Item className="btn-search">
                                                <Button block htmlType="submit" className="btn-red">Search</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full-width-section faqs-content full-width">
                    <div className="container">
                        <Row gutter={15} align="middle" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Collapse className="points-toknow" accordion bordered={false} defaultActiveKey={['1']} expandIconPosition={"right"}>
                                    <Panel header={<Title level={3} className="m-0 text-light-green font-700">POLICY BASICS</Title>} key="1">
                                        <Title level={4} className="text-red font-700">• How do I add another pet ?</Title>
                                        <Paragraph className="text-green">Each pet needs its own policy and you will have to set each policy one at a time. If you’re buying the policies during your first purchase, please select “add a pet”. If you wish to purchase another policy after having purchased a policy for your first pet, just login to your Oslo Pet Cloud and hit “add a pet” in your account to get a new quote for your other furry friend – and get a 5% multi-pet discount !</Paragraph>

                                        <Title level={4} className="text-red font-700">• Does Oslo offer a wellness plan?</Title>
                                        <Paragraph className="text-green">Currently, our policy does not offer coverage for routine wellness or preventative care yet. Examples of routine wellness or preventative care include, but are not limited to, annual wellness visits, annual or routine blood work, vaccinations, flea or tick preventative medications, etc. </Paragraph>

                                        <Title level={4} className="text-red font-700">• How does Oslo calculate my premium?</Title>
                                        <Paragraph className="text-green">In general, your premium is based on your pet's age, breed and gender. Some other factors that impact your premium are: the plan you choose, your deductible, your co-payment, and any discounts for which you are eligible. </Paragraph>

                                        <Title level={4} className="text-red font-700">• What is an annual deductible for pet insurance?</Title>
                                        <Paragraph className="text-green">An annual deductible is the fixed amount per policy period (one year) that needs to be met before we would begin to pay benefits for covered claims, at the elected reimbursement amount. Only covered claims will be applied to your annual deductible. Your deductible will reset when your policy renews each year.</Paragraph>

                                        <Title level={4} className="text-red font-700">• What is a co-insurance for pet insurance?</Title>
                                        <Paragraph className="text-green">Co-insurance  is the percentage of the vet bill the pet parent is responsible for versus what OSLO will pay . With Oslo  you   have the option to choose  between 70% /80% /90% . Keep in mind that this is applied to every claim.</Paragraph>

                                        <Title level={4} className="text-red font-700">• Are there age requirements for Oslo pet insurance policies?</Title>
                                        <Paragraph className="text-green">We will insure any cat or dog that is at least 8 weeks old and 8 years old for dogs and 10 years old for cats with lifetime covers. However, if you are renewing a policy for a dog that is 8 years or older, or for a cat that is 10 years or older, we ask that you follow your veterinarian’s advice regarding senior wellness testing. </Paragraph>

                                        <Title level={4} className="text-red font-700">• What if I am not sure about my pet’s age?</Title>
                                        <Paragraph className="text-green">If you do not know the exact date of birth of your pet, please use your best estimate based on your pet’s medical records or shelter/rescue documentation. We will validate the correct age when reviewing your pet's medical records.</Paragraph>

                                        <Title level={4} className="text-red font-700">• Does my pet have to be spayed or neutered to enroll?</Title>
                                        <Paragraph className="text-green">Your pet does not need to be spayed or neutered to purchase a policy and coverage is not affected based on if your pet is spayed/neutered or not. However, please keep in mind that our policy does not cover spaying or neutering.</Paragraph>

                                        <Title level={4} className="text-red font-700">• What is a pre-existing condition?</Title>
                                        <Title level={4} className="text-green font-700 mt-0">What is a pre-existing condition for pets?</Title>
                                        <Paragraph className="text-green">A pre-existing condition is any injury or illness whose symptoms were showing before the start of your pet’s policy, or during a relevant waiting period. A condition is considered pre-existing whether or not it’s been officially diagnosed or treated; all that matters is when its symptoms first displayed.</Paragraph>
                                        <Paragraph className="text-green">We are unable to determine a specific condition’s eligibility until you submit a claim, but for clarity’s sake, here are two hypothetical-only situations that speak to our general thought process:</Paragraph>
                                        <div className="points-toknow">
                                            <ul>
                                                <li className="text-green">Your Cocker Spaniel puppy is treated for Giardia pre-policy, and three weeks after you enroll her, she develops a case of some particularly watery runs. Her diarrhea may be considered a pre-existing condition because it’s a clinical symptom of Giardia, an ailment she’d been suffering from before her policy went into effect. Therefore, it won’t be covered. However, if your vet says the watery runs were caused by some other new condition, we may cover it. </li>
                                                <li className="text-green">Your senior Maine Coon is diagnosed with cancer during your Oslo policy waiting period. A year and a half later, he develops diabetes. His cancer wouldn’t be covered by Oslo because it began during a waiting period, but since his diabetes developed well into your policy, it’s not considered a pre-existing condition. Therefore, it could be covered.</li>
                                            </ul>
                                        </div>
                                        <Title level={4} className="text-green font-700 mt-0">What pet insurance covers pre-existing conditions?</Title>
                                        <Paragraph className="text-green">Pre-existing conditions aren’t covered by any pet insurance providers, including Oslo. Think of it this way: If your car is damaged or starts showing signs of engine failure, you wouldn’t buy an insurance policy after-the-fact on that vehicle and expect the provider to pay for repairs. The purpose of insurance is to protect you from unknowns: You can’t protect yourself against something that already happened or is happening.</Paragraph>

                                        <Title level={4} className="text-green font-700 mt-0">Can I still insure my pet if (s)he has a pre-existing condition?</Title>
                                        <Paragraph className="text-green">Of course! Pets with a pre-existing condition (or two, or even three) aren’t prohibited from enrolling in our policies; their coverage will just exclude those specific ailments.</Paragraph>

                                        <Title level={4} className="text-green font-700 mt-0">Is there anything I can do to avoid pre-existing condition exclusions  ?</Title>
                                        <Paragraph className="text-green">Yes, and it’s by enrolling your pet in a policy while they’re still healthy. Taking this sort of precaution improves their chances of being fully covered if something happens down the road. Note: If you have specific questions about your pet’s possible pre-existing conditions, just drop us a line to discuss yours further. We will not be able to guarantee coverage.</Paragraph>

                                        <Title level={4} className="text-red font-700">• How do you determine a pre-existing condition?</Title>
                                        <Paragraph className="text-green">We will evaluate your pet's medical history during the claim process to determine if the condition existed prior to your enrollment or within the subsequent waiting period. In general, a pre-existing condition is a medical condition which first showed signs or symptoms before the effective date of your Oslo policy or which occurred during the policy waiting period.</Paragraph>

                                        <Title level={4} className="text-red font-700">• Is a veterinary exam required to enroll for pet insurance?</Title>
                                        <Paragraph className="text-green">We do not require a veterinary exam to enroll. However, if you cannot provide medical records showing your pet’s annual health exam by a licensed veterinarian occurring within the 12 months prior to the effective date of your Oslo pet insurance policy, the first documented veterinary examination after the effective date of the policy may be used as a basis for determining any pre-existing conditions.</Paragraph>

                                        <Title level={4} className="text-green font-700">• What is an annual health exam?</Title>
                                        <Paragraph className="text-green">An annual health exam is the routine wellness exam that your vet recommends your pet has yearly to ensure that your pet is in good health. During this exam, your vet will examine your pet’s various systems, typically including their eyes, ears, throat, listening to their heart and breathing, completing an oral exam, and more.</Paragraph>
                                        <Paragraph className="text-green">Your veterinarian will make notes during this process. These doctor’s notes, along with notes from prior exams are what we will need to review when a claim is filed. For more information about what medical records are needed at the time of your pet’s first claim, visit our Medical Records FAQ.</Paragraph>

                                        <Title level={4} className="text-red font-700">• When does my policy start?</Title>
                                        <Paragraph className="text-green">Oslo policies are effective at 12:01am standard time at your mailing address the day after enrollment. </Paragraph>

                                        <Title level={4} className="text-green font-700">• Are there waiting periods?</Title>
                                        <Paragraph className="text-green">Yes. Visit Information on waiting periods.</Paragraph>

                                        <Title level={4} className="text-red font-700">• What medical records are needed from my veterinarian? </Title>
                                        <Title level={4} className="text-green font-700"><span className="text-red">•</span> What are medical records? </Title>
                                        <Paragraph className="text-green">Medical records are detailed notes that your veterinarian logs after each time your pet goes in for a visit. Medical records include the findings or results from a veterinary visit, including lab results, x-rays etc. In some cases, these notes are not something that your vet will provide to you up front, so you will need to request them. </Paragraph>

                                        <Title level={4} className="text-green font-700">• What do I need to submit with my first claim? </Title>
                                        <Paragraph className="text-green">To process your initial claim, we will need the last two years of medical records (dating back two years prior to the effective date of your pet's Oslo policy) including notes from all veterinarians your pet has visited. If your pet is less than two years old, or if they have not been a part of your family for at least two years, we will need all available records. If your pet was adopted, we will also need any medical records provided to you by the shelter or rescue organization including the date of adoption, as well as the adoption certificate if available. </Paragraph>
                                        <Paragraph className="text-green">Your pet's medical history should include complete veterinarian notes related to prior visits and any trips to a specialty hospital. </Paragraph>

                                        <Title level={4} className="text-green font-700">• What do I need to include with each future claim?</Title>
                                        <Paragraph className="text-green">For every claim you submit the following items are needed: </Paragraph>
                                        <ol className="text-green" style={{ fontSize: '18px' }}>
                                            <li>All notes related to the date of service associated with the claim. </li>
                                            <li>A clear, fully paid, itemized invoice.</li>
                                        </ol>
                                        <Title level={4} className="text-green font-700">• Where can I send the medical records?</Title>
                                        <Paragraph className="text-green">Your veterinarian can email the records. Your veterinarian can also email them to <a href="mailto:support@Osloinsurance.pet" className="font-600 text-green">support@Osloinsurance.pet</a>.</Paragraph>

                                        <Title level={4} className="text-red font-700">• How does Oslo compare to other pet insurance companies?</Title>
                                        <Paragraph className="text-green">Since we will make all competitors irrelevant choosing an Oslo pet insurance plan is the right decision for you and your pet. </Paragraph>

                                        <Title level={4} className="text-light-green font-700">What To Look For When You Compare Pet Insurance Plans</Title>
                                        <Paragraph className="text-green">Pet insurance companies may seem similar, but the differences can be significant when it matters most. In addition to pet insurance comparison reviews and ratings, here are some key policy differences to help you find the best pet insurance for your pet.</Paragraph>
                                        <div className="points-toknow">
                                            <ul>
                                                <li className="text-green"><span className="font-700">Coverage.</span> Look closely at covered medical expenses, and more importantly, expenses not covered when you compare pet insurance companies. All pet healthcare coverage details are provided upfront via the <span className="font-700">FAQs page</span>, sample policies, or you can <Link to={"/contact_us"} className="font-700 text-light-green">contact Customer Experience</Link></li>
                                                <li className="text-green"><span className="font-700">Annual deductible vs. per condition deductible.</span>  With our plans, once you have met your chosen annual deductible, you no longer pay a deductible for the remainder of your annual policy period. In contrast, a per condition deductible assigns deductibles per incident or condition—meaning cancer treatment may be subjected to a separate deductible than an ear infection. It is important to note that an annual deductible typically reimburses you more than per incident or per condition deductible. With OSLO you can chose your level of annual deductible.</li>
                                                <li className="text-green"><span className="font-700">Reimbursement.</span> Compare dog insurance or cat insurance for reimbursement details, as not all companies pay on the actual veterinary bill, and some don’t cover the examination fees. All Oslo pet insurance plans are covering examination fees related to covered injuries and illnesses, and all plans offer a waiver for the co-pay and deductible associated with life-saving treatment.</li>
                                                <li className="text-green"><span className="font-700">Claims Limits.</span> Some companies apply claim limits per incident, per year, or over the pet’s lifetime. Oslo does not limit the number of claims you can submit, nor will we raise your rates due to plan usage.</li>
                                                <li className="text-green"><span className="font-700">Benefits Schedule vs. Annual Plan Cap.</span> Plans that adhere to a benefits schedule will limit the total amount of money that will be paid out per condition. Oslo’s plans offer one annual plan cap, no sub-limit and the highest plan benefits in the market.</li>
                                                <li className="text-green"><span className="font-700">Pre-existing Conditions.</span> No pet insurance providers cover pre-existing conditions. Our definition of a pre-existing condition is any injury or illness whose symptoms were showing before the start of your pet’s policy, or during a relevant waiting period. (Note: A condition is considered pre-existing based on when its symptoms first displayed—regardless of whether or not it has been officially diagnosed or treated.).</li>
                                                <li className="text-green"><span className="font-700">Contract Terms and Customer Testimonials.</span> It is important to read all contract terms thoroughly, as misaligned expectations are often the root of dissatisfaction with a pet insurance provider.</li>
                                            </ul>
                                        </div>
                                        <Paragraph className="text-green">Be sure to research all pet insurance products—including customer reviews—before making a decision. Note: Some pet review websites accept membership fees from providers in exchange for placement on their site. </Paragraph>

                                        <Paragraph className="text-green">Also, it is important to read the contract terms thoroughly when you compare cat insurance or dog insurance. Often, dissatisfaction with pet insurance stems from misaligned expectations or misunderstanding of contract language, so it’s important to do your research and get the true pet insurance facts to find an insurer that meets your needs.</Paragraph>

                                        <Paragraph className="text-green"><em>These comparisons are based on documentation obtained from each of the above companies’ individual public websites and actual posted policies as of November 11, 2021. Coverage options are interpreted by Oslo FZE as being comparable. Plan details are subject to change. We encourage pet parents to conduct independent research before choosing a plan.</em></Paragraph>

                                        <Title level={4} className="text-red font-700">• Are discounts available? </Title>
                                        <Paragraph className="text-green">Available discounts can vary during the year and are subject to a maximum discount percentage.</Paragraph>
                                        <Paragraph className="text-green">Please <Link to={"/contact_us"} className="font-700 text-light-green">contact Customer Experience</Link> to determine which discounts are offered and to determine eligibility. </Paragraph>

                                        <Title level={4} className="text-red font-700">• Can I visit any veterinarian ?</Title>
                                        <Paragraph className="text-green">Of course ! We understand the importance of a trusting relationship between you and vet. If you purchased your plan in UAE, you may visit any licensed veterinarian in UAE. This includes specialists and emergency. With Oslo you are 100%.</Paragraph>

                                        <Title level={4} className="text-green font-700">• Is my coverage reduced for going to a specialist or emergency veterinary hospital? </Title>
                                        <Paragraph className="text-green">No, while some companies will reduce your reimbursements for visiting a specialist, we do not. We will pay you whatever reimbursement percentage you currently have on your policy, after your deductible is met and copay is applied, towards any covered item on a specialist or emergency veterinary hospital bill. </Paragraph>

                                        <Title level={4} className="text-green font-700">• Are there any special instructions for submitting my vet bills from another country? </Title>
                                        <Paragraph className="text-green">For the time being we are covering only UAE but soon we will be able to submit foreign veterinary bills in GCC.</Paragraph>

                                        <Title level={4} className="text-red font-700">• Is there a waiting period for pet insurance?</Title>
                                        <Paragraph className="text-green">Yes, starting from the effective date of your policy. Waiting periods may vary for accident and illness. Please contact Customer Experience for more information.</Paragraph>
                                    </Panel>
                                    <Panel header={<Title level={3} className="m-0 text-light-green font-700">ADMINISTRATION</Title>} key="2">
                                        <Title level={4} className="text-red font-700">• How do I cancel my policy?</Title>
                                        <Paragraph className="text-green">You may cancel your policy at any time by emailing us. We would be more than happy to discuss your cancellation over the phone.</Paragraph>
                                        <Paragraph className="text-green">Please, <Link to={"/contact_us"} className="font-700 text-light-green">contact Customer Experience</Link>.</Paragraph>
                                        <Paragraph className="text-green">Note: We do require the cancellation request in writing, via email. We offer a 30-day money back guarantee.</Paragraph>

                                        <Title level={4} className="text-red font-700">• How do I add a pet to an existing policy or account?</Title>
                                        <Paragraph className="text-green">Each pet has its own policy with its own plan, co-insurance, deductible options. The easiest way for you to enroll an additional pet is by logging into your <Link className="text-green text-underline" to={"/pet_cloud"}>Oslo Pet Cloud</Link>. Remember, you can always call us at +971 4 248 0561. We are more than happy to help! </Paragraph>

                                        <Title level={4} className="text-red font-700">• What are Oslo's hours of operation?</Title>
                                        <Title level={5} className="text-green font-700">Customer Experience (Central Time):</Title>
                                        <Paragraph className="text-green">Monday-Friday 8.30am-4.30pm | Saturday-Sunday contact support@osloinsurance.pet </Paragraph>

                                        <Title level={5} className="text-green font-700">Claims Support (Central Time): </Title>
                                        <Paragraph className="text-green">Monday-Friday 8.30am-4.30pm (claim-related support)</Paragraph>

                                        <Title level={4} className="text-red font-700">Does Oslo have a money back guarantee?</Title>
                                        <Paragraph className="text-green">We offer a 30-day money-back guarantee, so feel free to take a look and see what Oslo can do for you and your pet. If, within the first 30 days of your policy you wish to cancel, we will provide you with a full refund of the premium amount, provided you have not filed a claim. If you cancel your policy after the first 30 days or if you have submitted a claim, any possible refund is subject to terms and conditions.</Paragraph>

                                        <Title level={4} className="text-red font-700">What is the one-time administration fee?</Title>
                                        <Paragraph className="text-green">We may charge an enrollment fee. Please <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience</Link> to know more.</Paragraph>

                                        <Title level={4} className="text-red font-700">What happens to my pet insurance policy if I move? </Title>
                                        <Paragraph className="text-green">If you move within UAE, you are able to adjust your address via the “My Account” section in the Oslo Pet Cloud. You will also need to update your billing address via the “Insurance” section in the <Link className="text-green text-underline" to={"/pet_cloud"}>Oslo Pet Cloud</Link>. </Paragraph>
                                        <Paragraph className="text-green">If you are moving to a new country, please call us at +971 4 248 0561. We will need to assist in transferring your policy to your new country, in order to maintain your pre-existing conditions history. </Paragraph>

                                        <Title level={4} className="text-red font-700">Can I reinstate my coverage if my policy cancels due to non-payment?</Title>
                                        <Paragraph className="text-green">We know things happen and sometimes automatic payments don’t go through. That’s why if your policy cancels due to non-payment, we may reinstate the policy with no change in coverage. As long as there are no claims during the cancellation period, this will be an easy fix. </Paragraph>
                                        <Paragraph className="text-green">The timeframe in which you may reinstate your policy for non-payment will be subject to terms and conditions. <Link to={"/contact_us"} className="font-700 text-light-green text-underline">Contact Customer Experience</Link> at +971 4 248 0561. Or email us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-green">support@Osloinsurance.pet</a>.</Paragraph>
                                        <Paragraph className="text-green">If your policy has been cancelled for longer than the allowed timeframe, or if an illness or injury has occurred during the cancellation period, you are more than welcome to submit a new application for your pet online or by calling us. Your account will need to be paid up-to-date in order for the new policy to be issued. </Paragraph>
                                        <Paragraph className="text-green">Note: Waiting periods and pre-existing conditions start over apply to a new application.</Paragraph>

                                        <Title level={4} className="text-red font-700">Can I transfer my policy to a new owner?</Title>
                                        <Paragraph className="text-green">If the ownership of your pet changes to another individual, we are able to transfer the policy to the new pet owner without any interruption of coverage. To process this request, you will need to submit proof of new ownership, including the new owner's full name, address and phone number. We will also need to update the billing portion of the policy to include the new customer's preferred payment method prior to processing the policy transfer. </Paragraph>
                                        <Paragraph className="text-green">In order to start the transfer of ownership policy change, please reach out to <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience.</Link></Paragraph>

                                        <Title level={4} className="text-red font-700">Can I change my pet’s coverage?</Title>
                                        <Paragraph className="text-green">For questions about upgrading or downgrading your policy—subject to terms and conditions—please contact Customer Experience for more information. </Paragraph>

                                        <Title level={4} className="text-red font-700">How will I receive my policy documents?</Title>
                                        <Paragraph className="text-green">All policy documents will be conveniently sent by email. If you would prefer paper documentation mailed to you or if you have any questions about accessing this information, please <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience.</Link></Paragraph>
                                    </Panel>
                                    <Panel header={<Title level={3} className="m-0 text-light-green font-700">BILLING</Title>} key="3">
                                        <Title level={4} className="text-red font-700">Are there any payment fees? </Title>
                                        <Paragraph className="text-green">We may charge a payment processing fee or installment fees.</Paragraph>

                                        <Title level={4} className="text-red font-700">Can I change my billing date? </Title>
                                        <Paragraph className="text-green">Unfortunately, no. Once a policy is issued, you cannot change the billing date because it is linked to the effective date of your policy. </Paragraph>

                                        <Title level={4} className="text-red font-700">If I am paying annually, when is my premium due next? </Title>
                                        <Paragraph className="text-green">If you are paying annually: 10 days before your renewal, you will be billed for the annual premium amount for the next term. You may also <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience.</Link> if you have any questions.</Paragraph>

                                        <Title level={4} className="text-red font-700">What if my credit or debit card changes?</Title>
                                        <Paragraph className="text-green">To protect us from fraud credit card companies are frequently issuing new account and card numbers. We get this. If your payment is rejected for any reason please <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience.</Link></Paragraph>
                                    </Panel>
                                    <Panel header={<Title level={3} className="m-0 text-light-green font-700">CLAIMS</Title>} key="4">
                                        <Title level={4} className="text-red font-700">Do I need pre-authorization from Oslo for veterinary treatment?</Title>
                                        <Paragraph className="text-green">No, but if you would like more information as to if a treatment will be covered you may contact us at any time.</Paragraph>
                                        <Paragraph className="text-green">To request a pre-authorization of an upcoming treatment, please email us at <a href="mailto:support@Osloinsurance.pet" className="font-600 text-green">support@Osloinsurance.pet</a>, with an itemized estimate for the procedure and your pet’s full medical history. Pre-approval reviews may take up to 5-7 business days.</Paragraph>
                                        <Paragraph className="text-green">It is important that we have <span className="font-600 text-underline">your pet’s full medical history</span> because it will allow us to give you the best idea as to if something is covered. Please note: pre-authorization does not guarantee coverage, as Oslo will still need to review the claim once it has been submitted.</Paragraph>

                                        <Title level={4} className="text-red font-700">How do I appeal my claim?</Title>
                                        <Paragraph className="text-green">Please <Link to={"/contact_us"} className="font-700 text-light-green text-underline">contact Customer Experience.</Link> regarding your claim.</Paragraph>
                                        <Paragraph className="text-green">All requests to appeal your claim must be made in writing to us. Any submitted appeal should state clearly why you or your vet disagree with the initial determination and include supporting documentation. To appeal your claim simply email your request and supporting documentation to us at <Link to={"/contact_us"} className="font-700 text-light-green text-underline">Customer Experience.</Link></Paragraph>
                                        <Paragraph className="text-green">Once your appeal has been received by our Claims Department, you will receive an email confirmation.</Paragraph>
                                        <Paragraph className="text-green">Note: Appeals may take up to 30 days to be reviewed.</Paragraph>

                                        <Title level={4} className="text-red font-700">How do I file a claim?</Title>
                                        <Title level={4} className="text-gree font-700">Electronic/Paperless Claims Submission</Title>
                                        <ol className="text-green" style={{ fontSize: '18px' }}>
                                            <li>Log into your <Link className="text-green text-underline" to={"/pet_cloud"}>Oslo Pet Cloud</Link> from any device. </li>
                                            <li>Select the “Send a claim” card and answer the online questions.</li>
                                            <li>Upload/attach a copy of your paid invoice and submit your claim.</li>
                                            <li>Have your veterinarian send us your pet’s medical records.</li>
                                            <li>You’re done!</li>
                                        </ol>
                                        <Title level={4} className="text-gree font-700">Documents Required</Title>
                                        <div className="points-toknow">
                                            <ul>
                                                <li className="text-green">Medical Records</li>
                                                <li className="text-green">Paid Invoice </li>
                                            </ul>
                                        </div>
                                        <Paragraph className="text-green"><span className="font-700">Medical Records:</span> In order to process your (initial) claim, your veterinarian needs to send us the last 2 years of medical records including notes. Your veterinarian can email the records to your Oslo Pet Cloud directly using your Personal Pet Cloud Email Address. The email address is located at the top of the page after you log into your account on desktop or mobile. Your veterinarian can also email them to <a href="mailto:support@Osloinsurance.pet" className="font-600 text-green">support@Osloinsurance.pet</a>.</Paragraph>

                                        <Title level={4} className="text-gree font-700">Claims Reimbursement </Title>
                                        <Paragraph className="text-green">After your completed claims, along with all necessary invoices and medical records are submitted, we will work diligently to expedite your claim. We will process your claim within 30 days of our receipt of all required information, but our goal is 7-10 business days. </Paragraph>
                                        <Paragraph className="text-green">Note: Some claims can take longer to process if required documentation is not submitted in a timely fashion. </Paragraph>

                                        <Title level={4} className="text-red font-700">How do I get reimbursed or check my claim status?</Title>
                                        <Paragraph className="text-green">You can check the status of your claim by logging into the <Link className="text-green text-underline" to={"/pet_cloud"}>Oslo Pet Cloud</Link> and selecting the claims icon. Your reimbursement method is selected at the time of submission for each claim. </Paragraph>
                                        <Paragraph className="text-green">If you chose to be reimbursed via deposited directly into your bank account, this should arrive in your account within 3-5 business days following your claim being completed. If you are unsure about your chosen reimbursement method for a specific claim, feel free to reach out to <Link to={"/contact_us"} className="font-700 text-light-green text-underline">Oslo Customer Experience.</Link> </Paragraph>
                                        <Paragraph className="text-green">You can set up your preferred reimbursement account by logging into the <Link className="text-green text-underline" to={"/pet_cloud"}>Oslo Pet Cloud</Link> and selecting the claims icon. </Paragraph>

                                        <Title level={4} className="text-red font-700">How long do I have to file a claim? </Title>
                                        <Paragraph className="text-green">We ask that you file your claim within a reasonable timeframe following your pet’s illness or injury.</Paragraph>
                                        <Paragraph className="text-green">Please <Link to={"/contact_us"} className="font-700 text-light-green text-underline">Contact Customer Experience.</Link> for details regarding your policy’s claim filing period.</Paragraph>

                                        <Title level={4} className="text-red font-700">Should I file a claim if my bill is less than my deductible? </Title>
                                        <Paragraph className="text-green">Yes. All covered treatments will help you meet your annual deductible. And filing a claim does not impact your rates.</Paragraph>
                                    </Panel>
                                    <Panel header={<Title level={3} className="m-0 text-light-green font-700">RENEWAL</Title>} key="5">
                                        <Title level={4} className="text-red font-700">Will my premium increase?</Title>
                                        <Paragraph className="text-green">All pet insurance companies, no matter how they promote their plans, will raise rates accordingly to keep up with the rising cost of veterinary care. These necessary increases provide you the opportunity to offer your pet the best medical treatment options available.</Paragraph>
                                        <Paragraph className="text-green">Additionally, your premium will be affected by your pet's age each year, as the risk for more costly claims grows higher. Oslo strives to provide coverage well into your pet's old-age by not placing upper-age limitations on our policies.</Paragraph>
                                        <Paragraph className="text-green">Note: Your premium will never increase due to filing a claim.</Paragraph>
                                        <Paragraph className="text-green">Oslo will alert you to your policy renewal at least 30 days in advance, which allows time to review and make changes as needed. Your policy will renew automatically each year unless you notify Oslo that you wish to cancel.</Paragraph>

                                        <Title level={4} className="text-red font-700">How do I renew my policy?</Title>
                                        <Paragraph className="text-green">Your policy will renew automatically each year unless you notify Oslo that you wish to cancel. You can view your renewal declaration page at least 30 days prior to your policy’s expiration date.</Paragraph>
                                    </Panel>
                                </Collapse>
                                <Title level={3} className="mt-4 p-3 pb-0 text-light-green font-700">OUR COMMITMENT</Title>
                                <Paragraph className="text-green p-3">We take your feedback seriously and are always eager to hear how we can make Oslo an even more inclusive place for pet parents.</Paragraph>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        );
    }
}

export default faqs_page;