import React, { Component } from 'react';
import { Typography, Card, Row, Col, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Paragraph, Title } = Typography;

class UploadDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            filesObj: [],
            default: [],
        }
    }
    componentDidMount() {
        console.log('claimfile', this.props.claimObj.files);
        this.setState({ files: this.props.claimObj.files });
    }

    componentWillMount() {
        this.setState({ default: this.props.claimObj.files });
    }

    onChange = ({ file, fileList }) => {
        if (file.status !== 'uploading') {
            console.log('upfile', file);
            this.setState({ files: fileList });
        }
    }

    saveObjAndNext = () => {
        this.props.populateClaimObj('files', this.state.filesObj);
        this.props.actionNext('upload');
    }
    uploadDocuments = (options) => {
        const { file, onSuccess } = options;
        let filesObj = this.state.filesObj
        filesObj.push(file);
        this.setState({ filesObj })
        console.log('downfile', filesObj);
        onSuccess("Ok");
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">Now send us your claim documents</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={12} lg={20} md={15} sm={15} xs={24}>
                            <Upload multiple defaultFileList={this.state.default} customRequest={this.uploadDocuments} onChange={this.onChange} className={this.state.files ?.length ? "more-doc-upload" : "upload-doc"}>
                                <Button icon={this.state.files ?.length ? <UploadOutlined /> : <div><img src="/images/icon-doc.png" /></div>}>{this.state.files ?.length ? "+1 more document to send" : "Upload file"}</Button>
                            </Upload>
                            <div className="warning-text mt-4 text-left">
                                <img src="/images/icon-warning-red.png" />
                                <Paragraph className="text-green">
                                    {
                                        this.props.value === 'accident' ?
                                            "Please make sure it’s clear and legible! Bills must show each item, indicate full payment and be sent with medical reports." :
                                            this.props.value === 'lost' ?
                                                "Please make sure it’s clear and legible! Bills must indicate the price and full payment." :
                                                this.props.value === 'assistance' ?
                                                    "Please make sure it’s clear and legible! You will need to send us a certification & hospitalization from your doctor & invoice paid from licsensed keneel or cattery." :
                                                    this.props.value === 'passed_away' ?
                                                        "Please send us a vet's verification of death, a proof of purchase of your pet and a cremation and/or burial facilities invoices paid." :
                                                        "Please make sure it’s clear and legible! Please send us a certification from your vet that immediate life saving treatment was needed & invoices and a proof of payment for all travel and accommodation costs ."

                                    }
                                </Paragraph>
                            </div>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('upload')}>
                                        <i className="fa fa-angle-up"></i>
                                    </Button>
                                </Col>
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button disabled={!this.state.files ?.length} block size="large" className="btn-wizard rounded-100" onClick={() => this.saveObjAndNext()}>
                                        <i className="fa fa-angle-down"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">Bear with us a little longer</span>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default UploadDocuments;