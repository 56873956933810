import React, { Component, Fragment } from "react";
import { Layout, Image } from 'antd';
import { Link } from "react-router-dom";

import policySummery from '../../assets/images/oslo-policy-summary.pdf'
const { Footer } = Layout;

class footer extends Component {
    render() {
        return (
            <Fragment>
                <Footer className="footer pb-2 pt-5 text-center">
                    <div className="container">
                        <div className="social-links">
                            <ul>
                                <li>
                                    <a href="https://www.instagram.com/osloinsurance.pet/" target={'_blank'} rel="noreferrer">
                                        <Image
                                            preview={false}
                                            alt='instagram'
                                            className="img-fluid"
                                            src="/images/instagram.png"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/osloinsurance/?ref=py_c" target={'_blank'} rel="noreferrer">
                                        <Image
                                            preview={false}
                                            alt='facebook'
                                            className="img-fluid"
                                            src="/images/facebook.png"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/oslo-pet-insurance/" target={'_blank'} rel="noreferrer">
                                        <Image
                                            preview={false}
                                            className="img-fluid"
                                            alt='linkedin'
                                            src="/images/linkedin.png"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-nav">
                            <ul className="quick-links">
                                <li>
                                    <Link to={"/faqs"}>FAQs</Link>
                                </li>
                                <li>
                                    <Link to={"/about_us"}>About Us</Link>
                                </li>
                                <li>
                                    <a href="https://osloinsurance.pet/blog/" target="_blank">Blog</a>
                                </li>
                                <li>
                                    <Link to={"/contact_us"}>Contacts</Link>
                                </li>
                                <li>
                                    <Link to={"/terms_of_use"}>Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to={"/privacy_policy"}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <a href={policySummery} target={'_blank'} rel="noreferrer">
                                        Policy Summary
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="bottom-footer full-width mb-4 mt-4">
                                <p className="underwriter-text">The information contained in this website is for illustrative purposes only and coverage under any pet insurance policy is expressly subject to the terms and conditions of the policy documentation issued by the insurer. Coverage underwritten by Al-Ittihad Al-Watani (AIAW), registered under UAE Federal Law No. 6 of 2007 with Insurance Authority License No. 42 and Commercial License No. 200345 dated 21/11/1976. Live vet and Oslo pet cloud are separate non insurance services unaffiliated with AIAW.</p>
                                <a  href="https://www.alittihadalwatani.com/" target={'_blank'} rel="noreferrer" className="insurer-logo-holder">
                                    <img className="img-fluid" src="/images/al-ittihad-al-watani-blue.jpg" alt="Al-Ittihad Al-Watani (AIAW)"/>
                                </a>
                                <p className="mt-4">
                                © 2021 OSLO FZE, Dubai, United Arab Emirates - All Rights Reserved.
                                </p>
                        </div>
                    </div>
                </Footer>
            </Fragment>
        );
    }
}

export default footer;
