import React, { Component, Fragment } from 'react';
import { Layout, Typography, Button, Row, Col, Card, Table, MonitorOutlined, Pagination  } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import AdminPortalHeader from '../../components/common/admin_portal_header';
import { getPoliciesForAdmin, getClaimsForAdmin } from '../../api/pets-api';
import { PolicyStatus, ClaimStatus } from '../../api/constants';
import moment from 'moment';


const { Paragraph, Title } = Typography;
const { Content } = Layout;


class AdminClaims extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            userPets:[],
            policies:[],
            claims:[],
            progress:[],
            completed:[],
            selectedPetId: 0,
            currentBenefits: 0,
            currentRemainingBenefits: 0,
            currentDeductable: 0,
            currentRemainingDeductable: 0,
            tabPosition: 'left',
            setTabPosition: 'left' ,
            tableTitle: 'Unprocessed Claims',
            selectedButton: 1,
            currentPage:1,
            pageSize:10,
            totalRecs:0,
            currentStatus:ClaimStatus.new_claim
        };
    }
    
    getClaims = async (status, title, button) => {
        if(!button)
            button = 1;
        
        this.setState({tableTitle:title, selectedButton:button, currentStatus:status});

        try{
            let respo = await getClaimsForAdmin(status, this.state.currentPage, this.state.pageSize);
            console.log(respo);
            let claims = [];
            let recCount = 0;
            if(respo.status == 200){
                claims = respo.data.result.result;
                recCount = respo.data.result.count;
                let claimsProcessed = claims.map((itm) => {
                    itm.claimSerial = "C1/" + moment(itm.createdAt).format('YYYY/MM')+ "/00000" + itm.serial;
                    return itm;
                })
                this.setState({claims:claimsProcessed, totalRecs:recCount});
            }else{
                this.setState({claims:[], totalRecs:0});
            }
        }catch(ex){
            this.setState({claims:[], totalRecs:0});
        }
    }

    onPaginationChange = async (pageNumber) => {
        await this.setState({currentPage:pageNumber});
        await this.getClaims(this.state.currentStatus, this.state.tableTitle, this.state.selectedButton)
    }

    showTotal = (total) => {
        return `Total ${total} Records`
    }

    loadClaimsOnStateChange = async (status, title, button) => {
        await this.setState({currentPage:1});
        await this.getClaims(status, title, button);
    }

    async componentDidMount() {
        await this.loadClaimsOnStateChange(ClaimStatus.new_claim, 'New Claims');
    }

    navigateToClaim(claimNo){
        console.log('Claim Number', claimNo);
        window.location.pathname = '/admin_claims/'+claimNo
    }


    render() {

        const columns = [
            {
                title: 'Claim',
                dataIndex: 'claimSerial',
                render: (claimSerial) => <Fragment key={1}>
                    
                    <Paragraph className="text-muted p-0 mb-0"> {claimSerial} </Paragraph>
                </Fragment>
            },
            {
                title: 'What Happen',
                dataIndex: 'whatHappen',
                render: whatHappen => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{whatHappen?.toUpperCase()}</Paragraph>
                </Fragment>
            },
            {
                title: 'Incident Date',
                dataIndex: 'incidentDate',
                render: incidentDate => <Fragment key={2}>
                    
                    <Paragraph className="text-muted p-0 mb-0">{moment(incidentDate).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'Reported Date',
                dataIndex: 'createdAt',
                render: createdAt => <Fragment key={2}>
                    <Paragraph className="text-muted p-0 mb-0">{moment(createdAt).format('MM/DD/YYYY')}</Paragraph>
                </Fragment>
            },
            {
                title: 'View More',
                dataIndex: 'id',
                render: (id) => <Fragment key={2}>
                    <Button htmlType="button" type="primary" shape="circle" onClick={() => this.navigateToClaim(id)}  >
                        <SearchOutlined  />
                    </Button>
                </Fragment>
            },
        ];

        return (
            <Layout className="dashboard claims">
                <AdminPortalHeader />
                <div className="m-2">
                    <Title level={2} className="text-green font-600 pt-3 ps-3 m-0">Claims</Title>
                    <Row gutter={15}>
                        <Col className='pt-3 px-3' xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Button htmlType="button" className={this.state.selectedButton == 1 ? "full-width btn-category btn-blue" : "full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.new_claim, 'New Claims', 1)} >New Claims</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 2 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.in_process, 'In Progress', 2)} >In Progress</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 3 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.approved_by_vet, 'Approved By Vet', 3)} >Approved By Vet</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 4 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.declined_by_vet, 'Declined By Vet', 4)} >Declined By Vet</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 5 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.accepted, 'Accepted', 5)} >Accepted</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 6 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.rejected, 'Rejected', 6)} >Rejected</Button>
                            <Button htmlType="button" className={this.state.selectedButton == 7 ? "mt-3 full-width btn-category btn-blue" : "mt-3 full-width btn-category"} size='large' onClick={(btn) => this.loadClaimsOnStateChange(ClaimStatus.paid, 'Paid', 7)} >Paid</Button>
                        </Col>
                        <Col className='pt-3 px-3' xs={18} sm={18} md={18} lg={18} xl={18}>
                            <Card className="custom-shadow green" title={<Title level={4} className="text-center text-white">{this.state.tableTitle}</Title>}>
                                <Table className="noborder" columns={columns} dataSource={this.state.claims} pagination={false} size="small" />
                                
                                {
                                    this.state.totalRecs > 0 &&
                                    <div>
                                        <hr></hr>
                                        <Pagination current={this.state.currentPage} total={this.state.totalRecs} onChange={async (page) => await this.onPaginationChange(page)} showTotal={this.showTotal} />
                                    </div>
                                }
                                
                            </Card>
                        </Col>
                    </Row>
                </div>
                
                
            </Layout>
        );
    }
}

export default AdminClaims;