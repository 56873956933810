import React, { Component } from 'react';
import { Typography, Card, Row, Col, Radio, Button } from 'antd';

const { Title } = Typography;

class PetProblemCategories extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.props.populateClaimObj('whatHappen',this.props.value)
    }

    render() {
        return (
            <Row gutter={[15, 30]} align="middle">
                <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                    <Title level={3} className="text-green font-700 mb-5">Oh no, what happened to your pet buddy?</Title>
                    <Row gutter={[15, 50]} justify="center">
                        <Col xl={10} lg={24} md={15} sm={20} xs={24}>
                            <Radio.Group className="custom-radio" onChange={this.props.changePetProblem} value={this.props.value}>
                                <Radio className="w-100 text-green" value={'accident'}>S.he is sick/had an accident</Radio>
                                <Radio className="w-100 text-green" value={'lost'}>I lost my pet</Radio>
                                <Radio className="w-100 text-green" value={'assistance'}>I need pet assistance</Radio>
                                <Radio className="w-100 text-green" value={'passed_away'}>My pet passed away</Radio>
                                <Radio className="w-100 text-green" value={'cancel_vacation'}>I had to cancel my vacation</Radio>
                            </Radio.Group>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionPrev('category')}>
                                        <i className="fa fa-angle-up"></i>
                                    </Button>
                                </Col>
                                <Col xl={2} lg={4} md={3} sm={3}>
                                    <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('category')}>
                                        <i className="fa fa-angle-down"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                    <Card className="text-center border-green rounded-10 w-100">
                        <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                        <span className="text-green h5">We’re getting kinda intrusive</span>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default PetProblemCategories;