import React, { Component, Fragment } from 'react';
import { Layout, Typography, Card, Row, Col, Button, Input, Spin, Form, Modal } from 'antd';
import VetNotify from './vet_notify';
import PetAuthonized from './pet_authonized';
import VacatoinBooked from './vacation_booked';
import { getUser, setUser, notifications } from './../../../helpers/utilities';
import { updateUser } from './../../../api/pets-api';


const { Paragraph, Title } = Typography;


class PaymentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIncident: '',
            disabled: true,
            accvisible: false,
            accspinning: false,
            userInfo: {}
        }
    }

    componentDidMount(){
        let userInfo = getUser();
        this.setState({ userInfo });
        this.setState({
            currentIncident: (this.props.claimObj.amount&&this.props.from==='upload') ? 'done' : this.props.value, 
            disabled: this.props.claimObj.amount?false:true})

        console.log('props',this.props.value,this.props.from);
        if(!userInfo.iban){
            this.showAccModal();
        }
        
    }

    next = () => {
        if(this.state.userInfo)
        this.setState({ currentIncident: 'done' });
    }

    prev = () => {
        this.props.actionPrev()
    }
    paymentPrev = () => {
        this.setState({currentIncident: this.props.value},()=>{
            (this.state.currentIncident === 'accident' || this.state.currentIncident === 'assistance') && this.props.actionPrev() 
        });
    }

    
    showAccModal = () => {
        this.setState({
            accvisible: true,
        });
    };

    handleAccCancel = e => {
        console.log(e);
        this.setState({
            accvisible: false,
        });
    };

    onAccFinish = (values) => {
        let user = this.state.userInfo;
        let iban = values.iban;
        let bank_name = values.bank_name;
        let bank_address = values.bank_address;
        let swift_code = values.swift_code;
        user.iban = iban;
        user.bankName = bank_name;
        user.bankAddress = bank_address;
        user.swiftCode = swift_code;
        console.log('updateduser', user);

        this.setState({ accspinning: true }, async () => {
            try {
                await updateUser(user);
                this.setState({
                    accvisible: false,
                    accspinning: false
                });
                setUser(user);

            } catch (err) {
                console.log(err);
                notifications('Something went wrong!', 'Error', 'error', 3)
            }
        });

        // console.log('Received values of form: ', values);
    };

    render() {
        return (
            <>
                {
                    (this.state.currentIncident === 'accident' || this.state.currentIncident === 'assistance'  || this.state.currentIncident === 'done') &&
                    <Row gutter={[15, 30]} align="middle">
                        <Col xl={17} lg={13} md={24} sm={24} xs={24}>
                            <Title level={3} className="text-green font-700 mb-5">How much did you pay?</Title>
                            <Row gutter={[15, 50]} justify="center">
                                <Col xl={12} lg={20} md={15} sm={15} xs={24}>
                                    <Input
                                        type='number'
                                        className="payment-field"
                                        placeholder="00.00"
                                        value={this.props.claimObj.amount}
                                        onChange= {(e)=>{this.props.populateClaimObj('amount',e.target.value); this.setState({disabled: (e.target.value===''?true:false)})}}
                                        suffix={<span className="text-green">AED</span>}
                                    />
                                    <div className="warning-text mt-2 text-left">
                                        <img src="/images/icon-warning-red.png" />
                                        <Paragraph className="text-green">
                                            {
                                                this.props.value === 'accident' ?
                                                    "Please indicate the full amount paid, inclusive of consultation, treatment and medications." :
                                                    this.props.value === 'lost' ?
                                                        "Please indicate the full amount paid for advertising and reward due to theft/straying and/or your pet purchase prize." :
                                                        this.props.value === 'assistance' ?
                                                            "Please indicate the full amount paid for keneel." :
                                                        this.props.value === 'passed_away' ?
                                                            "Please indicate the full amount paid for cremation and/or burial facilities." :
                                                            "Please indicate the full amount paid for your vacation."
                                                        
                                            }
                                        </Paragraph>
                                    </div>
                                </Col>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Row gutter={[15, 50]} justify="center">
                                        <Col xl={2} lg={4} md={3} sm={3}>
                                            <Button block size="large" className="btn-wizard rounded-100" onClick={() => this.paymentPrev()}>
                                                <i className="fa fa-angle-up"></i>
                                            </Button>
                                        </Col>
                                        <Col xl={2} lg={4} md={3} sm={3}>
                                            <Button disabled={this.state.disabled} block size="large" className="btn-wizard rounded-100" onClick={() => this.props.actionNext('payment')}>
                                                <i className="fa fa-angle-down"></i>
                                            </Button>
                                            <Modal
                                                centered
                                                title="My Account Info"
                                                visible={this.state.accvisible}
                                                onCancel={this.handleCancel}
                                                footer={false}
                                            >
                                                <Spin className="spinner-wrp" spinning={this.state.accspinning} size="medium" />
                                                <Paragraph className="text-green">
                                                Please fill in your bank details in order to fulfill the claim.
                                                </Paragraph>
                                                <Form size="large" name="normal_login" className="customise-form"
                                                    onFinish={this.onAccFinish}
                                                    initialValues={{
                                                        iban: this.state.userInfo.iban || '',
                                                        bank_name: this.state.userInfo.bank_name || '',
                                                        bank_address: this.state.userInfo.bank_address || '',
                                                        swift_code: this.state.userInfo.swift_code || '',
                                                    }}
                                                >
                                                    <Form.Item
                                                        name="iban"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter IBAN!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="IBAN" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="bank_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter Name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Bank Name"
                                                        /></Form.Item>
                                                        <Form.Item
                                                        name="bank_address"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Bank Address"
                                                        /></Form.Item>
                                                        <Form.Item
                                                        name="swift_code"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter Swift Code!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Swift Code"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item className="mb-0">
                                                        <Button type="primary" htmlType="submit" className="btn-dark-green" block>
                                                            Update
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={7} lg={11} md={24} sm={24} xs={24}>
                            <Card className="text-center border-green rounded-10 w-100">
                                <img className="pet-pic" src="/images/oslo-pet-thumbnail.png" />
                                <span className="text-green h5">Bear with us a little longer</span>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    this.state.currentIncident === 'lost' &&
                    <VetNotify claimObj={this.props.claimObj} populateClaimObj={this.props.populateClaimObj} actionNext={this.next} actionPrev={this.prev}></VetNotify>
                }
                {
                    this.state.currentIncident === 'passed_away' &&
                    <PetAuthonized claimObj={this.props.claimObj} populateClaimObj={this.props.populateClaimObj} actionNext={this.next} actionPrev={this.prev}></PetAuthonized>
                }
                {
                    this.state.currentIncident === 'cancel_vacation' &&
                    <VacatoinBooked claimObj={this.props.claimObj} populateClaimObj={this.props.populateClaimObj} actionNext={this.next} actionPrev={this.prev}></VacatoinBooked>
                }
            </>

        );
    }
}

export default PaymentComponent;